.App {
  text-align: center;
}

* {
  margin: 0;
  padding: 0;
  -ms-overflow-style: none; /* Internet Explorer */
  scrollbar-width: none; /* Firefox */
}

*::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
