@font-face {
  font-family: PlutoSansRegular;
  src: url(../assets/fonts/PlutoSansDPDRegular.otf);
}

@font-face {
  font-family: PlutoSansMedium;
  src: url(../assets/fonts/PlutoSansMedium.otf);
}

@font-face {
  font-family: PlutoSansLight;
  src: url(../assets/fonts/PlutoSansDPDLight.otf);
}
