/* Overwriting the original mapbox styles like this as the Popup component is made up of two Divs */

.mapboxgl-popup-tip {
  background: none !important;
  margin-bottom: 0.5rem;
}

.mapboxgl-popup-content {
  background-color: #000 !important;
  color: #fff !important;
  border: 2px solid #fff !important;
  font-weight: 700 !important;
  padding: 4px !important;
  display: flex;
  align-items: center;
  gap: 2px;
}

/* Popup simulated data */

.popupSimulatedContainer {
  display: flex;
  align-items: center;
  padding-left: 0.25rem;
  gap: 2px;
}

/* Icon for the Popup */

.popupIcon {
  width: 8px !important;
  height: 10px !important;
  margin: 0 1px 1px 2.5px;
}
