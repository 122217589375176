.sliderContainer {
  position: absolute;
  display: flex;
  /* flex-direction: row; */
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  padding: 0 0.5rem 0.5rem 1rem;
  z-index: 199;
  background-color: rgba(0, 0, 0, 0.7);
  gap: 0.1rem;
  width: 100%;
  height: fit-content;
}

.slider {
  position: relative;
  /* flex-direction: row; */
  height: 50%;
  width: 100%;
  padding: 1rem 2rem 0 0;
}

.sliderCheckboxContainer {
  color: #fff;
  display: flex;
  font-size: 0.95rem;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  height: 20%;
  user-select: none;
  margin-top: 0.5rem;
}

.sliderCheckbox {
  cursor: pointer;
  padding-right: 2px;
}

.sliderButtonContainer {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.3rem;
}

.sliderButton {
  cursor: pointer;
  padding: 2px 4px;
  width: 2.3rem;
  border: none;
  border-radius: 5px;
}

.sliderButtonActive {
  background-color: rgba(220, 0, 50, 1);
  color: #fff;
}
