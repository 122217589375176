.container {
  position: relative;
  width: 100%;
}

.header {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 0.5rem;
  width: 100%;
  transition: opacity 0.3s ease-in-out;
}

.miniHeader {
  display: flex;
  background-color: #fff;
  padding: 0.5rem 0.5rem;
  color: #3a3a3a;
  font-size: 16px;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  height: fit-content;
}

.miniHeaderSegment {
  display: flex;
  gap: 0.2rem;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  white-space: nowrap;
}

.miniHeaderValues {
  font-size: 0.8rem;
  color: #3a3a3a;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.toggleButton {
  position: absolute;
  bottom: -17px; /* Moves the button below the header */
  right: 0px;
  background-color: #fff;
  color: #3a3a3a;
  border: none;
  padding: 0 0.5rem;
  cursor: pointer;
  border-bottom-left-radius: 0.25rem;
  font-size: 14px;
  z-index: 1000;
}

.headerOptions {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  color: #fff;
  font-size: 12px;
  width: 100%;
}

.headerMainSegment {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}

.headerSegment {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
  color: #fff;
}

.headerVolumeDateContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
}

.headerMenu {
  display: flex;
  gap: 0.5rem;
}

.headerNotInUse {
  color: #aaa;
  cursor: not-allowed !important;
}

.headerDepotSearchBox,
.headerPostcodeSearchBox,
.headerOverlayDateInput {
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  caret-color: #000;
  color: #000;
  padding: 0 !important;
  width: 100%;
}

.headerDepotSearchBox::placeholder,
.headerPostcodeSearchBox::placeholder,
.headerOverlayDateInputPlaceholder {
  color: rgba(0, 0, 0, 0.8);
}

.headerPostcodeSearchBox {
  padding-bottom: 0.2rem;
  border-bottom: 1px solid #fff;
}

.headerLocationDropDown {
  overflow: scroll !important;
  display: inline-block;
  position: absolute;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0.5rem 0;
  left: 85px;
  top: 40px;
  color: #000;
  background-color: #fff;
  list-style-type: none;
  z-index: 200;
  min-height: 1px;
  max-height: 30rem;
  margin-bottom: 1rem;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox and Chrome */
}

/* .headerLocationDropDownValue {
  padding: 0.3rem 0.5rem;
  margin-bottom: 0.2rem;
  cursor: pointer;
  background-color: none;
  transition: background-color 0.3s ease-in-out;
  height: auto;
  width: auto;
} */

.headerLocationDropDownValue {
  color: #aaa !important;
  font-size: 0.9rem;
}

.headerLocationDropDownValue:hover {
  background-color: rgba(200, 200, 200, 0.5);
  padding: 0.3rem 0.5rem;
}

input:focus {
  outline-width: 0;
}

.headerUserName {
  font-weight: 600;
}

.headerIcon {
  cursor: pointer;
  width: 15px !important;
  height: 18px !important;
}

.headerChevronIcon {
  transform: rotate(180deg);
}

.headerChevronIconOpen {
  transform: rotate(90deg);
}

.overlayTotalStopsContainer {
  flex-direction: column;
  padding: 0.3rem 1rem;
  gap: 0.5rem;
}

.overlayTotalStopsMinimisedContainer {
  flex-direction: row;
  gap: 1rem;
}

.overlayTotalStopsContainer,
.overlayTotalStopsMinimisedContainer {
  color: #3a3a3a;
  width: fit-content;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlayTotalStopsSubContainer {
  display: flex;
  gap: 0.2rem;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  white-space: nowrap;
}

.overlayTotalStops {
  font-size: 0.8rem;
  align-self: flex-end;
  color: #3a3a3a;
}
