.simulationContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0rem 1rem;
  background-color: #fff;
}

.sideBarButton {
  color: #fff;
  font-size: 13px;
  border: none;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
  border-radius: 5px;
  background-color: rgba(220, 0, 50, 1);
  font-weight: 700;
  padding: 0.25rem;
}

.sideBarButton:hover {
  background-color: rgba(255, 0, 0, 1);
}

.simulationSideBarTitle {
  text-align: left;
  font-size: 0.9rem;
  color: #666;
}

.simulationSliderContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  background-color: #fff;
}

.sideBarSimulateButtonAltText {
  font-size: 0.8rem;
}
